@import '../../styles/customMediaQueries.css';

.root {
  h1 {
    margin: 29px 0 14px 0;

    @media (--viewportMedium) {
      margin: 32px 0 32px 0;
    }
  }

  h2 {
    text-align: center;
  }
}

.list {
  list-style-type: disc;
  list-style: inside;
  margin-top: 8px;
  line-height: 1.6;
}

li {
  /*composes: signaliseListItemStyle from global;*/
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}
.bookingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 260px;

  /* Margin */
  margin-bottom: 48px;
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  /* max-width: 750px; */
  margin: auto;
}

.contentContainer {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  @media (--viewportMedium) {
    width: 193px;
    margin-right: 16px;
    margin-top: 8px;
    align-self: flex-start;
  }
  @media (max-width: 768px) {
    align-self: center;
    margin: 0 auto;
  }
}
.intro {
  margin: 0 0 30px;
}

.contentMain {
  width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    max-width: 750px;
  }
}

.subtitle {
  composes: h3 fontWeightSemiBold from global;
  margin-top: 32px;
  margin-bottom: 16px;
}
.videoSection {
  margin: 15px 0 30px;
}
.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hand,
.hiFive {
  max-height: 180px;
  max-width: 180px;
  object-fit: contain;
}
